// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-careers-listing-js": () => import("./../../../src/pages/careers/listing.js" /* webpackChunkName: "component---src-pages-careers-listing-js" */),
  "component---src-templates-404-index-js": () => import("./../../../src/templates/404/index.js" /* webpackChunkName: "component---src-templates-404-index-js" */),
  "component---src-templates-ai-machine-learning-index-js": () => import("./../../../src/templates/AiMachineLearning/index.js" /* webpackChunkName: "component---src-templates-ai-machine-learning-index-js" */),
  "component---src-templates-approach-index-js": () => import("./../../../src/templates/Approach/index.js" /* webpackChunkName: "component---src-templates-approach-index-js" */),
  "component---src-templates-blog-index-js": () => import("./../../../src/templates/Blog/index.js" /* webpackChunkName: "component---src-templates-blog-index-js" */),
  "component---src-templates-blogetry-index-js": () => import("./../../../src/templates/Blogetry/index.js" /* webpackChunkName: "component---src-templates-blogetry-index-js" */),
  "component---src-templates-careers-index-js": () => import("./../../../src/templates/Careers/index.js" /* webpackChunkName: "component---src-templates-careers-index-js" */),
  "component---src-templates-case-study-index-js": () => import("./../../../src/templates/CaseStudy/index.js" /* webpackChunkName: "component---src-templates-case-study-index-js" */),
  "component---src-templates-contact-index-js": () => import("./../../../src/templates/Contact/index.js" /* webpackChunkName: "component---src-templates-contact-index-js" */),
  "component---src-templates-dev-ops-index-js": () => import("./../../../src/templates/DevOps/index.js" /* webpackChunkName: "component---src-templates-dev-ops-index-js" */),
  "component---src-templates-home-index-js": () => import("./../../../src/templates/Home/index.js" /* webpackChunkName: "component---src-templates-home-index-js" */),
  "component---src-templates-innovation-index-js": () => import("./../../../src/templates/Innovation/index.js" /* webpackChunkName: "component---src-templates-innovation-index-js" */),
  "component---src-templates-iot-embedded-index-js": () => import("./../../../src/templates/IotEmbedded/index.js" /* webpackChunkName: "component---src-templates-iot-embedded-index-js" */),
  "component---src-templates-people-index-js": () => import("./../../../src/templates/People/index.js" /* webpackChunkName: "component---src-templates-people-index-js" */),
  "component---src-templates-privacy-index-js": () => import("./../../../src/templates/Privacy/index.js" /* webpackChunkName: "component---src-templates-privacy-index-js" */),
  "component---src-templates-product-index-js": () => import("./../../../src/templates/Product/index.js" /* webpackChunkName: "component---src-templates-product-index-js" */),
  "component---src-templates-resource-index-js": () => import("./../../../src/templates/Resource/index.js" /* webpackChunkName: "component---src-templates-resource-index-js" */),
  "component---src-templates-resources-index-js": () => import("./../../../src/templates/Resources/index.js" /* webpackChunkName: "component---src-templates-resources-index-js" */),
  "component---src-templates-salesforce-index-js": () => import("./../../../src/templates/Salesforce/index.js" /* webpackChunkName: "component---src-templates-salesforce-index-js" */),
  "component---src-templates-services-index-js": () => import("./../../../src/templates/Services/index.js" /* webpackChunkName: "component---src-templates-services-index-js" */),
  "component---src-templates-software-development-index-js": () => import("./../../../src/templates/SoftwareDevelopment/index.js" /* webpackChunkName: "component---src-templates-software-development-index-js" */),
  "component---src-templates-strategy-index-js": () => import("./../../../src/templates/Strategy/index.js" /* webpackChunkName: "component---src-templates-strategy-index-js" */),
  "component---src-templates-technology-index-js": () => import("./../../../src/templates/Technology/index.js" /* webpackChunkName: "component---src-templates-technology-index-js" */),
  "component---src-templates-ux-design-index-js": () => import("./../../../src/templates/ux-design/index.js" /* webpackChunkName: "component---src-templates-ux-design-index-js" */),
  "component---src-templates-web-mobile-app-development-index-js": () => import("./../../../src/templates/WebMobileAppDevelopment/index.js" /* webpackChunkName: "component---src-templates-web-mobile-app-development-index-js" */),
  "component---src-templates-work-index-js": () => import("./../../../src/templates/Work/index.js" /* webpackChunkName: "component---src-templates-work-index-js" */)
}

