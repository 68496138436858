import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import Footer from './Footer/index';
import Navbar from './Navbar/index';
import './reset.css';
import 'bulma/css/bulma.css';
import './all.css';
import useSiteMetadata from './SiteMetadata';
import { withPrefix } from 'gatsby';
import AppContext from '../contexts/AppContext';

const TemplateWrapper = ({ children }) => {
  const [activePage, setActivePage] = useState(null);
  const { title, description } = useSiteMetadata();
  
  return (
    <div>
      <AppContext.Provider value={{ activePage, setActivePage }}>
        <Helmet>
          <html lang="en" />
          <title>{title}</title>
          <meta name="description" content={description} />
          <link
            rel="stylesheet"
            href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,600&display=swap"
          />
          <link
            rel="stylesheet"
            href="https://fonts.googleapis.com/css?family=Roboto+Slab:300,400,500,600&display=swap"
          />
          <link
            rel="icon"
            type="image/svg"
            href={`${withPrefix('/')}img/favicon.svg`}
            sizes="32x32"
          />
          <meta name="theme-color" content="#fff" />
          <meta property="og:type" content="business.business" />
          <meta property="og:title" content={title} />
          <meta property="og:url" content="/" />
          <meta
            property="og:image"
            content={`${withPrefix('/')}img/og-image.jpg`}
          />
        </Helmet>
        <Navbar />
        <div>{children}</div>
        <Footer />
      </AppContext.Provider>
    </div>
  );
};

export default TemplateWrapper;
