import React, { useEffect, useState, useContext } from 'react';
import { globalHistory } from '@reach/router';
import { Link } from 'gatsby';
import AppContext from '../../contexts/AppContext';
import './index.css';
import logo from '../../img/logo.svg';
import minus from '../../img/minus.svg';
import plus from '../../img/plus.svg';

const Navbar = () => {
  const { activePage } = useContext(AppContext);
  const [isOpen, setIsOpen] = useState(false);
  const [servicesOpen, setServicesOpen] = useState(false);
  const [peopleOpen, setPeopleOpen] = useState(false);

  useEffect(() => {
    return globalHistory.listen(({ action }) => {
      if (action === 'PUSH') setIsOpen(false);
    });
  }, [setIsOpen]);

  return (
    <nav className="navbar" role="navigation" aria-label="main-navigation">
      <div className="root-padding">
        <Link className="logo-link" to="/">
          <img src={logo} alt="Logo" />
        </Link>
      </div>
      {isOpen && (
        <div className="mini-menu">
          <div className={`container ${servicesOpen ? 'active' : ''}`}>
            <div className="top">
              {servicesOpen ? (
                <img
                  onClick={() => setServicesOpen(false)}
                  src={minus}
                  alt="Minus"
                />
              ) : (
                <img
                  onClick={() => setServicesOpen(true)}
                  src={plus}
                  alt="Plus"
                />
              )}
              <Link to="/services" className="c-title">
                SERVICES
              </Link>
            </div>
            {servicesOpen && (
              <div className="bottom">
                <Link to="/services/strategy">strategy</Link>
                <Link to="/services/ux-design">design</Link>
                <Link to="/services/innovation">innovation</Link>
                <Link to="/services/product-management">product management</Link>
                <Link to="/services/technology">technology</Link>
              </div>
            )}
          </div>
          <div className={`container`}>
            <Link to="/work">WORK</Link>
          </div>
          <div className="container">
            <Link to="/our-approach">APPROACH</Link>
          </div>
          <div className={`container ${peopleOpen ? 'active' : ''}`}>
            <div className="top">
              {peopleOpen ? (
                <img
                  onClick={() => setPeopleOpen(false)}
                  src={minus}
                  alt="Minus"
                />
              ) : (
                <img
                  onClick={() => setPeopleOpen(true)}
                  src={plus}
                  alt="Plus"
                />
              )}
              <Link to="/people" className="c-title">
                PEOPLE
              </Link>
            </div>
            {peopleOpen && (
              <div className="bottom">
                <Link to="/careers">Careers</Link>
              </div>
            )}
          </div>
          <div className={`container`}>
            <Link to="/blog">INSIGHTS</Link>
          </div>
          <Link to="/contact" className="contact-btn">
            Contact
          </Link>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
