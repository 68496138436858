import React from 'react';

import { Link } from 'gatsby';

import './index.css';
import logoSmall from '../../img/logo-small.svg';
import footerBackground from '../../img/footer-background.svg';

export default () => {
  const bottomLinks = () => (
    <div className="bottom-links">
      <Link className="mr-5" to="/privacy-policy">
        Privacy Policy
      </Link>
    </div>
  );

  const socialMediaLinks = () => (
    <div className="social-media">
      <a
        href="https://www.facebook.com/devetry/"
        target="_blank"
        rel="noreferrer"
        aria-label="Connect with us on Facebook"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="11.718"
          height="22.044"
          viewBox="0 0 11.718 22.044"
        >
          <defs></defs>
          <path
            className="a"
            d="M130.872,12.306l.607-3.959h-3.8V5.777a1.98,1.98,0,0,1,2.232-2.139h1.727V.268A21.064,21.064,0,0,0,128.574,0c-3.129,0-5.174,1.9-5.174,5.329V8.347h-3.478v3.959H123.4v9.572a13.846,13.846,0,0,0,4.281,0V12.306Z"
            transform="translate(-119.922 0)"
          />
        </svg>
      </a>
      <a
        href="https://twitter.com/devetry"
        target="_blank"
        rel="noreferrer"
        aria-label="Connect with us on Twitter"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24.326"
          height="19.769"
          viewBox="0 0 24.326 19.769"
        >
          <defs></defs>
          <path
            className="a"
            d="M24.327,5.34a9.906,9.906,0,0,1-2.867.785,5.009,5.009,0,0,0,2.2-2.76,9.994,9.994,0,0,1-3.169,1.21,5,5,0,0,0-8.506,4.552A14.172,14.172,0,0,1,1.694,3.913a5,5,0,0,0,1.544,6.664,4.976,4.976,0,0,1-2.26-.624c0,.021,0,.043,0,.062a4.994,4.994,0,0,0,4,4.894A5.014,5.014,0,0,1,2.728,15a5,5,0,0,0,4.661,3.466,10.011,10.011,0,0,1-6.2,2.137A10.086,10.086,0,0,1,0,20.529a14.137,14.137,0,0,0,7.65,2.241,14.1,14.1,0,0,0,14.2-14.2c0-.216-.006-.432-.015-.646A10.124,10.124,0,0,0,24.327,5.34Z"
            transform="translate(-0.001 -3)"
          />
        </svg>
      </a>
      <a
        href="https://www.linkedin.com/company/devetry-inc-/"
        target="_blank"
        rel="noreferrer"
        aria-label="Connect with us on Linkedin"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="21.907"
          height="21.906"
          viewBox="0 0 21.907 21.906"
        >
          <defs></defs>
          <path
            className="a"
            d="M29.671,12H12.235A2.235,2.235,0,0,0,10,14.235V31.671a2.235,2.235,0,0,0,2.235,2.235H29.671a2.235,2.235,0,0,0,2.235-2.235V14.235A2.235,2.235,0,0,0,29.671,12ZM16.793,29.736H13.852V20.273h2.941ZM15.3,19.112a1.473,1.473,0,1,1,.039-2.941,1.474,1.474,0,1,1-.039,2.941ZM28.132,29.736H25.191V24.492c0-1.219-.426-2.051-1.49-2.051a1.615,1.615,0,0,0-1.509,1.1,2.117,2.117,0,0,0-.1.735v5.457H19.154V23.292c0-1.18-.039-2.167-.077-3.019h2.554l.135,1.316h.058a3.4,3.4,0,0,1,2.922-1.529c1.935,0,3.386,1.3,3.386,4.083v5.592Z"
            transform="translate(-10 -12)"
          />
        </svg>
      </a>
    </div>
  );

  const smallLogo = () => (
    <>
      <Link to="/">
        <img src={logoSmall} alt="Small logo" />
      </Link>
    </>
  );
  return (
    <footer>
      <div className="secondary-padding big-footer">
        <img
          style={{ pointerEvents: 'none' }}
          src={footerBackground}
          alt="Footer background"
        />
        <div className="left">
          <p className="footer-title">
            That's all for <br /> now.
          </p>
          <div>
            <div className="is-flex mb-5">
              <div className="is-flex-direction-column mr-6">
                <p>
                  <strong>Denver</strong>
                </p>
                <a
                  href="https://www.google.com/maps/place/Devetry/@39.770774,-104.9704646,15z/data=!4m5!3m4!1s0x0:0x78f6ae4956e35462!8m2!3d39.7709275!4d-104.9706682"
                  target="_blank"
                  rel="noreferrer"
                  aria-label="Connect with us on Linkedin"
                >
                  3858 Walnut St <br className="small"></br> Denver, CO 80205
                </a>
              </div>
              <div className="is-flex-direction-column mr-6">
                <p>
                  <strong>Portland</strong>
                </p>
                <a
                  href="https://www.google.com/maps/place/Industrious+Pioneer+Square/@45.5183169,-122.6815708,17z/data=!3m1!4b1!4m5!3m4!1s0x54950b5ccefae4b9:0x6774fc2d76ad26e5!8m2!3d45.51835!4d-122.6793692"
                  target="_blank"
                  rel="noreferrer"
                  aria-label="Connect with us on Linkedin"
                >
                  811 SW 6th Ave Suite 1000 <br className="small"></br>{' '}
                  Portland, OR 97204
                </a>
              </div>
            </div>
            {bottomLinks()}
          </div>
        </div>
        <div className="right">
          <div>{socialMediaLinks()}</div>
          {smallLogo()}
        </div>
      </div>
      <div className="secondary-padding small-footer">
        <div className="contact-info-container">{smallLogo()}</div>
        <div className="is-flex">
          <div className="is-flex-direction-column mr-6">
            <p>
              <strong>Denver</strong>
            </p>
            <a
              href="https://www.google.com/maps/place/Devetry/@39.770774,-104.9704646,15z/data=!4m5!3m4!1s0x0:0x78f6ae4956e35462!8m2!3d39.7709275!4d-104.9706682"
              target="_blank"
              rel="noreferrer"
              aria-label="Connect with us on Linkedin"
            >
              3858 Walnut St <br className="small"></br> Denver, CO 80205
            </a>
          </div>
          <div className="is-flex-direction-column mr-6">
            <p>
              <strong>Portland</strong>
            </p>
            <a
              href="https://www.google.com/maps/place/Industrious+Pioneer+Square/@45.5183169,-122.6815708,17z/data=!3m1!4b1!4m5!3m4!1s0x54950b5ccefae4b9:0x6774fc2d76ad26e5!8m2!3d45.51835!4d-122.6793692"
              target="_blank"
              rel="noreferrer"
              aria-label="Connect with us on Linkedin"
            >
              811 SW 6th Ave Suite 1000 <br className="small"></br> Portland, OR
              97204
            </a>
          </div>
        </div>
        {socialMediaLinks()}
        {bottomLinks()}
      </div>
    </footer>
  );
};
